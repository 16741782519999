import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../Images/logo192.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTelegram, faYoutube } from '@fortawesome/free-brands-svg-icons';


const Footer = () =>  {
    return (
      <footer >

        <div className='footer-container'>

          <div className='footer-content'>
           <h3>اتصل بنا</h3>
           <p>Email: info@yconners.com </p>
           <p>Address: Egypt- Giza</p>

          </div>
          <div className='footer-content'>
            <h3>الصفحات</h3>
              <ul className='list'>
                <li><Link to='/'>الصفحة الرئيسية</Link></li>
                <li><Link to='/Privacy'>سياسة الخصوصية</Link></li>
                <li><Link to='/TermsOfUsePage'>سياسة الاستخدام</Link></li>
                <li><Link to='/TermsAndConditions'>الشروط والاحكام</Link></li>
                <li><Link to='/About'>من نحن</Link></li>
                <li><Link to='/ContactUs'>اتصل بنا</Link></li>
              </ul>
          </div>
          <div className='footer-content'>
            <h3>التواصل الإجتماعي</h3>
            <ul className='social'>
        <li >
          <Link to="https://www.facebook.com/yconners1" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} /> فيسبوك
          </Link>
        </li>
        <li>
        <Link to="https://www.youtube.com/@YasserThabet87" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} /> يوتيوب
          </Link>
        </li>
        <li>
        <Link to="https://t.me/yconners" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTelegram} /> تليجرام
          </Link>
        </li>
      </ul>
      <Link to="/"><img src={logo} alt='logo' loading='lazy'/></Link>






          </div>




        </div>

        <div className='bottom-bar'>
        <p>جميع الحقوق محفوظة &copy; 2024 YCONNERS </p>

        </div>

        
       
     
       
       
  
  
        
      </footer>
      
  
    );
  }
  
  export default Footer;